<template>
  <v-card
    class="mx-auto"
  >
    <v-card-text>
      <p class="text-h4 text--primary">
        RSS
      </p>
      <v-row
        v-if="RSSs"
        no-gutters
      >
        <v-col
          v-for="(rss, index) in RSSs"
          :key="`${index}`"
          cols="12"
          sm="12"
          md="12"
        >
          <v-card
            class="pa-2"
            outlined
            tile
          >
            <v-row
              no-gutters
            >
              <v-col
                cols="12"
                sm="4"
                md="4"
              >
                {{rss.Name}}
              </v-col>
              <v-col
                cols="12"
                sm="8"
                md="8"
              >
                {{rss.URL}}
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import apiBrightsign from '@/services/apiBrightsign'
export default {
  props: {
    presentationID: {
      type: String,
      required: true,
    },
  },
  data: () => ({
    loading: false,
    RSSs: null,
  }),
  watch: {
    presentationID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetData()
  },
  methods: {
    handleGetData () {
      this.RSSs = null
      if (!this.presentationID) return
      apiBrightsign.getAll(`/v1/private/presentations/${this.presentationID}/rss`)
        .then(response => {
          this.RSSs = response
          this.loading = false
        })
        .catch(error => {
          console.log(error)
          this.loading = false
        })
    },
  },
}
</script>

